export default defineNuxtRouteMiddleware((to) => {
  const { $ROUTE } = useNuxtApp()
  const auth = useAuth()

  if (
    [
      $ROUTE.PAYMENTS_OVERVIEW,
      $ROUTE.PAYMENTS_STATEMENTS,
      $ROUTE.PROFILE_CHANGE_BANK_DETAILS,
      $ROUTE.PROFILE_PAYMENT_INFO,
    ].includes(to.path) &&
    !auth.profile.permissions?.ViewPayments
  ) {
    return navigateTo($ROUTE.INDEX)
  }
})
